import Vue from 'vue'
import VueRouter from 'vue-router'
import Info from "@/views/Info";
import ProgrammingSkills from "@/views/ProgrammingSkills";
import DesignSkills from "@/views/DesignSkills";
import Portfolio from "@/views/Portfolio";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Info',
    component: Info
  },
  {
    path: '/information',
    name: 'Info',
    component: Info
  },
  {
    path: '/programming-skills',
    name: 'ProgrammingSkills',
    component: ProgrammingSkills
  },
  {
    path: '/design-skills',
    name: 'DesignSkills',
    component: DesignSkills
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: Portfolio
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
