<template>
   <div class="route-page">
       <div>
           <h1>Programming Skills </h1>

          <v-sheet  class=" mt-16">
              <v-simple-table class="mt-4">
                  <template v-slot:default>
                      <thead>
                      <tr>

                          <th class="text-left f18">
                              Skill
                          </th>
                          <th class="text-left f18">
                              Rate
                          </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                              v-for="(item,i) in skills"
                              :key="i"
                      >
                          <td class="f20">{{ item.title }}</td>
                          <td>
                              <v-progress-linear
                                      color="#05bb9e"
                                      height="12"
                                      :value="loaded?item.rate:0"


                              ></v-progress-linear>
                          </td>
                      </tr>
                      </tbody>
                  </template>
              </v-simple-table>
          </v-sheet>

       </div>

   </div>
</template>

<script>
    export default {
        name: "ProgrammingSkills",
        data(){
            return{
                skills:[
                    {title:'Vue.js',rate:90,color:'green'},
                    {title:'PHP and Laravel',rate:85,color:'info'},
                    {title:'Rest Api',rate:95,color:'orange'},
                    {title:'JavaScript , jQuery',rate:80,color:'primary'},
                    {title:'MySql',rate:88,color:'primary'},
                    {title:'GitHub',rate:70,color:'primary'},
                    {title:'C# , DevExpress',rate:87,color:'primary'},
                    {title:'SQL Server',rate:79,color:'primary'},
                    {title:'HTML',rate:100,color:'success'},
                    {title:'CSS , CSS3 , SCSS , Bootstrap',rate:90,color:'green'},

                ],
                loaded:false
            }
        },
        methods:{
            load(){

                this.loaded = true;
            }
        },
        created(){
            setTimeout(this.load,300);
        }
    }
</script>

<style scoped>

</style>