<template>
   <div class="route-page">
       <div>
           <h1>Design Skills</h1>

          <v-sheet  class=" mt-16">
              <v-simple-table class="mt-4">
                  <template v-slot:default>
                      <thead>
                      <tr>

                          <th class="text-left f18">
                              Skill
                          </th>
                          <th class="text-left f18">
                              Rate
                          </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                              v-for="(item,i) in skills"
                              :key="i"
                      >
                          <td class="f20">{{ item.title }}</td>
                          <td>
                              <v-progress-linear
                                      color="#05bb9e"
                                      height="12"
                                      :value="loaded?item.rate:0"


                              ></v-progress-linear>
                          </td>
                      </tr>
                      </tbody>
                  </template>
              </v-simple-table>
          </v-sheet>

       </div>

   </div>
</template>

<script>
    export default {
        name: "DesignSkills",
        data(){
            return{
                skills:[
                    {title:'Cinema 4D',rate:70,color:'green'},
                    {title:'Adobe After Effects',rate:80,color:'green'},
                    {title:'Adobe Illustrator',rate:70,color:'green'},
                    {title:'Adobe Photoshop',rate:90,color:'green'},



                ],
                loaded:false
            }
        },
        methods:{
            load(){

                this.loaded = true;
            }
        },
        created(){
            setTimeout(this.load,300);
        }
    }
</script>

<style scoped>

</style>