<template>
    <v-container>
        <div class="route-page pa-4">
            <h1>Information</h1>
            <div style="overflow: hidden;width: 100%;">
                <div class="panel ma-auto" style="width: 100%;text-align: center">
                    <v-card class="ma-auto mt-4 pa-4" max-width="600" elevation="4">
                        <v-card-title>About Me</v-card-title>
                        <v-divider/>
                        <div class="resume-p">

                            <p>
                                I am Hassan Mohammed, born in 1994 from Iraq - Baghdad,I obtained a bachelor’s degree in
                                law in 2017, but I have been working as developer since 2010. I have worked on most
                                programming platforms from websites, desktop applications and mobile applications.
                            </p>

                        </div>
                    </v-card>
                    <v-sheet max-width="600" class="ma-auto mt-2">
                        <v-timeline>
                            <v-timeline-item

                                    v-for="(year, i) in years"
                                    :key="i"
                                    :color="year.color"
                                    small
                            >
                                <template v-slot:opposite>
                            <span
                                    :class="`headline font-weight-bold ${year.color}--text`"
                                    v-text="year.year"
                            ></span>
                                </template>
                                <div class="py-4">
                                    <h2 :class="`headline font-weight-light mb-4 ${year.color}--text`">
                                        {{year.title}}
                                    </h2>
                                    <div>
                                        {{year.text}}
                                    </div>
                                </div>
                            </v-timeline-item>
                        </v-timeline>
                    </v-sheet>
                </div>
            </div>

        </div>
    </v-container>

</template>

<script>
    export default {
        name: "Info",
        data() {
            return {
                years: [
                    {
                        color: 'cyan',
                        year: '2010',
                        title:'Freelancer',
                        text:'i worked as freelancer programmer'
                    },
                    {
                        color: 'warning',
                        year: '2013',
                        title:'Employee',
                        text:'i worked in company remotely'
                    },
                    {
                        color: 'primary',
                        year: '2017',
                        title:'Employee',
                        text:'i worked in University Full Time'
                    },
                    {
                        color: 'success',
                        year: '2019',
                        title:'Employee',
                        text:'i worked in Company Full Time'
                    },
                    {
                        color: 'error',
                        year: '2022',
                        title:'Employee',
                        text:'i worked in Company Full Time'
                    },


                ],
            }
        }
    }
</script>

<style scoped>
    .resume-p {
        font-size: 18px;
        margin: 0 auto;
        margin-top: 30px;
        /*letter-spacing: 0.1em; !* Adjust as needed *!*/
        text-align: justify;
        /*text-transform: uppercase;*/
        /*font-style: italic;*/


    }

    .panel {
        animation: todown linear .8s;
    }

    @keyframes todown {
        0% {
            margin-top: -1000px;
            opacity: 0;
        }
        60% {
            margin-top: -80px;
            opacity: 0.1;
        }
        100% {
            margin-top: 0px;
            opacity: 1;
        }

    }


</style>