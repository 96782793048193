<template>
    <div class="route-page">
        <v-container>
            <h1>Some Websites </h1>
            <v-row >
                <v-col cols="12" md="6" lg="4" v-for="(site , i) in sites" :key="i">
                    <v-card

                            class="mx-auto my-12"
                            max-width="374"
                    >
                        <template slot="progress">
                            <v-progress-linear
                                    color="deep-purple"
                                    height="10"
                                    indeterminate
                            ></v-progress-linear>
                        </template>

                        <v-img
                                contain
                                height="250"
                                :src="site.image"
                        ></v-img>

                        <v-card-title>{{site.title}}</v-card-title>

                        <v-card-text>
                            <v-row
                                    align="center"
                                    class="mx-0"
                            >
                                <v-rating
                                        :value="site.rate"
                                        :color="site.color"
                                        dense
                                        half-increments
                                        readonly
                                        size="14"
                                ></v-rating>

                                <div class="grey--text ms-4">
                                    {{site.rate}}
                                </div>
                            </v-row>

                            <div class="my-4 text-subtitle-1">
                                {{site.subtitle}}
                            </div>

                            <div>
                                {{site.text}}
                            </div>
                        </v-card-text>

                        <v-divider class="mx-4"></v-divider>

                        <v-card-title>Languages</v-card-title>

                        <v-card-text>
                            <v-chip-group
                                    column
                            >
                                <v-chip v-for="(lan , y) in site.languages" :key="y" color="deep-purple" dark>{{lan.label}}</v-chip>

                            </v-chip-group>
                        </v-card-text>

                        <v-card-actions>
                            <v-btn
                                    :href="site.url"
                                    color="deep-purple lighten-2"
                                    text
                            >
                                Visit
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: "Portfolio",
        data(){
            return{
                sites:[
                    {
                        image:'/images/1.jpg',
                        rate:4,
                        color:'amber',
                        title:'LANA LIFESTYLE',
                        subtitle:'For Sell Products',
                        text:'for the import and trade agencies of cosmetics and Hair and skin care from the most famous European brands',
                        url:'https://lana-lifestyle.com',
                        languages:[
                            {label:'HTML'},{label:'CSS'},{label:'Vue'},{label:'Laravel'},{label:'MySql'},{label:'Api'},
                        ]
                    },
                    {
                        image:'/images/2.jpg',
                        rate:5,
                        color:'amber',
                        title:'MYNET',
                        subtitle:'user: admin pass: 123456',
                        text:'System for management services customers',
                        url:'https://test.my-net.pro',
                        languages:[
                            {label:'HTML'},{label:'CSS'},{label:'Vue'},{label:'Laravel'},{label:'MySql'},{label:'Api'},
                        ]
                    },
                    {
                        image:'/images/6.jpg',
                        rate:4,
                        color:'amber',
                        title:'ORDERS',
                        subtitle:'user:ali  pass: 123456',
                        text:'System for management services customers as shopping and store to sell products',
                        url:'https://orders-iq.com',
                        languages:[
                            {label:'HTML'},{label:'CSS'},{label:'Vue'},{label:'Laravel'},{label:'MySql'},{label:'Api'},
                        ]
                    },
                    {
                        image:'/images/4.jpg',
                        rate:3,
                        color:'amber',
                        title:'MYBOX',
                        subtitle:'For Movies',
                        text:'Website for watching movies',
                        url:'#',
                        languages:[
                            {label:'HTML'},{label:'CSS'},{label:'jQuery'},{label:'JavaScript'},{label:'Bootstrap'},
                        ]
                    },

                ]
            }
        }
    }
</script>

<style scoped>

</style>