<template>
  <!-- App.vue -->

  <v-app>



<div class="mt-16 mb-8">
  <template>
    <v-fab-transition v-model="$vuetify.breakpoint.xs">
      <v-btn
              @click="openDrawer"
              color="warning"
              fab
              dark
              small
              absolute

              right
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-fab-transition>
  </template>
</div>


    <v-navigation-drawer app dark  v-model="$store.state.drawer">
      <Drawer/>
    </v-navigation-drawer>
    <!-- Sizes your content based upon application components -->
    <v-main>

      <!-- Provides the application the proper gutter -->
      <v-container fluid>

        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

  </v-app>
</template>

<script>
import Drawer from "@/components/Drawer";
export default {
  name: 'App',
  components:{
    Drawer
  },
  data: () => ({

  }),
  methods:{
    openDrawer(){
      this.$store.state.drawer = !this.$store.state.drawer;
      console.log(this.$store.state.drawer)
    }
  }
};
</script>
<style>
  @import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Cairo:wght@500;700&family=Josefin+Sans:wght@700&family=Righteous&display=swap');

  .f16{
    font-size: 16px !important;
  }
  .f18{
    font-size: 18px !important;
  }
  .f20{
    font-size: 20px !important;
  }


  *{
    font-family: 'Cairo', sans-serif;

  }
  .route-page{
    animation: route linear 0.5s;

  }
  @keyframes route {
    0%{
      margin-left: -2000px;
    }
    50%{
      margin-left: -200px;
    }
    60%{
      margin-left: -80px;
    }
    90%{
      margin-left: -10px;
    }
    100%{

      margin-left: 0px;

    }
  }

</style>