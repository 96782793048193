<template>

<div>
        <v-list nav class="pa-0">
            <div class="warning">
                <v-list-item class="ma-0 pa-0">
                    <div class="cover-cover-image d-flex">
                        <div class="cover-image align-self-center ma-auto">
                            <v-img width="100" src="/profile/me.jpg" />
                        </div>
                    </div>



                </v-list-item>

            </div>
            <v-divider/>
            <v-list-item>
                <div class="name text-center" style="width: 100%">
                    HASSAN MOHAMMED
                </div>
            </v-list-item>
            <v-divider/>
            <v-list-item link to="/information">
                <v-list-item-icon>
                    <v-icon>mdi-information</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Information</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link to="/programming-skills">
                <v-list-item-icon>
                    <v-icon>mdi-xml</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Programming Languages</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link to="/design-skills">
                <v-list-item-icon>
                    <v-icon>mdi-palette-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Graphic Design</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link to="/portfolio">
                <v-list-item-icon>
                    <v-icon>mdi-sticker-check-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Portfolio</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link href="https://github.com/hassanbaghdad?tab=repositories">
                <v-list-item-icon>
                    <v-icon>mdi-github</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>My GitHub</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item link href="mailto:mr.iraq1994@gmail.com">
                <v-list-item-icon>
                    <v-icon>mdi-email</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>mr.iraq1994@gmail.com</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link href="tel:009647714283610">
                <v-list-item-icon>
                    <v-icon>mdi-phone</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>+9647714283610</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

        </v-list>

</div>

</template>

<script>
    export default {
        name: "Drawer",
        data(){
            return{
                items: [
                    ['mdi-information', 'Information'],
                    ['mdi-xml', 'Programming Languages'],
                    ['mdi-palette-outline', 'Graphic Design'],
                    ['mdi-sticker-check-outline', 'My Works'],
                    ['mdi-email', 'Email'],
                    ['mdi-phone', 'phone'],

                ],
                show:false
            }
        }
    }
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Cairo:wght@500;700&family=Josefin+Sans:wght@700&family=Righteous&display=swap');

    .name{
        font-family: 'Righteous', cursive;
        font-size: 20px;
        transition: linear .2s;

    }
    .name:hover{
       color: #ffb700 ;
    }

    *{
        font-family: 'Cairo', sans-serif;


    }
    .mydrawer{
        position: relative;
    }
    .mydrawer::after {
        content: "";
        background: url(https://img.freepik.com/free-vector/copy-space-blue-circuits-digital-background_23-2148821699.jpg?w=2000);
        opacity: 0;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
    }
    .cover-cover-image{
        padding: 10px;
        width: 100%;
    }
    .cover-image{
        border-radius: 50%;
        overflow: hidden;
        border: 5px solid white;
    }
</style>